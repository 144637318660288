import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import img1 from '@images/partners/sdc.png';
import img2 from '@images/partners/questglobal.jpg';
import adorno from "@images/adorno/adorno4.png";

const Wrapper = styled.div`
color: black;
font-weight: bold;
padding-top: 1rem;
display: flex;
flex-direction: column;
align-items: center;
text-align: justify;
`;

const CardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-bottom: 2rem;
@media (min-width: 768px){
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
  align-items: stretch;
}
`;

const Card = styled.a`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 0.5rem 1rem 0.5rem;
margin: 0.5rem;
cursor:pointer;
color:var(--tema4);

img{
  object-fit:contain;
  height: 100px;
}
h1{
  font-size: 1rem;
  text-align: center;
}
p{
  text-align: center;
  font-size: 0.8rem;
}

@media (min-width: 768px){
  width: 40%;
}

@media (min-width: 1024px){
  width: 30%;
}

:hover{
  img{
    animation: animateHeart 1.2s infinite;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

`;


const IndexPage = () => {




  return (
    <TemplatePage1
      title={"Aliados globales"}
      tc={"var(--tema4)"}
      adorno={<Adorno/>}
      hero={false}
      content={(
        <Wrapper >
          <p> Las redes QuEST se fundamentan en la conexion de investigadores, gobiernos, empresas y fuerzas de desarrollo nacionales e internacionales. Todos estan siempre enfocados en nuestro principal objetivo conjunto, que es: Generar Evidencia. Esto permitirá la mejora continua para obtener sistemas de salud de alta calidad. Todo esto a través de mejoras en los métodos de medición, prueba de soluciones y creación de conocimiento generalizable</p>
          <CardContainer>
           
            <Card href="https://www.shareweb.ch/site/Health/Pages/Welcome.aspx" target="_blank" rel="noreferrer">
              <img src={img1} alt={img1}/>
              <h1>SDC Health Network</h1>
            </Card>
          
         
          </CardContainer>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage






const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 0;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
right: 400px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left:0;
right:auto;
height:100%;
  width: 600px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;